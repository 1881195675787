import {createFeatureSelector, createSelector} from '@ngrx/store';
import {ModuleState, PageSaveState, PageState} from './veranstaltungselektion.reducer';
import {ListInputItem} from '../../shared/sopi-list/sopi-list.model';
import {Veranstaltung} from '../../../clients/incidents';

export const getModuleState = createFeatureSelector<ModuleState>('VeranstaltungselektionPage');

export const getPageState = createSelector(getModuleState, (state: ModuleState) => state.page);

export const getVeranstaltungen = createSelector(getPageState, (state: PageState) => state.veranstaltungen);

export const getPageSaveState = createSelector(getModuleState, (state: ModuleState) => state.veranstaltungselektionPage);

export const getNurVeranstaltungenMitOffenenIncidents = createSelector(getPageSaveState, (state: PageSaveState) => state.nurVeranstaltungenMitOffenenIncidents);

export const getSelectedVeranstaltungsIds = createSelector(getPageSaveState, (state: PageSaveState) => state.selectedVeranstaltungsIds);

export const getVeranstaltungenList = createSelector(getVeranstaltungen,
        (veranstaltungen: Array<Veranstaltung>) => {
            const res: Array<ListInputItem> = [];
            if (veranstaltungen !== undefined && veranstaltungen !== null) {

                veranstaltungen.forEach((v) => {
                    let group = 'Aktiv';
                    if (v.veranstaltung_id === '00000000-0000-0000-0000-000000000000') {
                        group = '';
                    }
                    if (v.ist_beendet) {
                        group = 'Archiviert';
                    }
                    res.push(<ListInputItem>{
                        group: group,
                        groupId: group,
                        groupTitle: group,
                        itemId: v.veranstaltung_id,
                        itemTitle: v.titel,
                        item: v,
                    });
                });
            }
            return res;
        }
);

export const getVeranstaltungenLaden = createSelector(getPageState, (state: PageState) => state.veranstaltungenLaden);

export const getVeranstaltungenFehler = createSelector(getPageState, (state: PageState) => state.veranstaltungenFehler);
