import * as api from '../../../clients/incidents'; 

import {Action} from '@ngrx/store';

export const GET_ZENTRALE_EINGANG2 = '[IncidentsModul] GetZentraleEingang2 laden';
export const GET_ZENTRALE_EINGANG2_AUSFUEHREN = '[IncidentsModul] GetZentraleEingang2 ausführen';
export const GET_ZENTRALE_EINGANG2_ERFOLGREICH = '[IncidentsModul] GetZentraleEingang2 laden erfolgreich';
export const GET_ZENTRALE_EINGANG2_FEHLER = '[IncidentsModul] GetZentraleEingang2 Ladefehler';
export const GET_ZENTRALE_EINGANG2_NICHT_AUSGEFUEHRT = '[IncidentsModul] GetZentraleEingang2 nicht ausgeführt (evtl. Offline)';



export class GetZentraleEingang2Action implements Action {
    readonly type = GET_ZENTRALE_EINGANG2;
        constructor( public veranstaltung_id:Array<string> , public optPayload: any = null) {}
    }
export class GetZentraleEingang2AusfuehrenAction implements Action {
    readonly type = GET_ZENTRALE_EINGANG2_AUSFUEHREN;
        constructor( public veranstaltung_id:Array<string> , public optPayload: any = null) {}
    }
export class GetZentraleEingang2ErfolgreichAction implements Action {
    readonly type = GET_ZENTRALE_EINGANG2_ERFOLGREICH;
        constructor(public payload: Array<api.IncidentKopf>,  public veranstaltung_id:Array<string> , public optPayload: any = null) {}
    }
export class GetZentraleEingang2FehlerAction implements Action {
    readonly type = GET_ZENTRALE_EINGANG2_FEHLER;
        constructor(public payload: any,  public veranstaltung_id:Array<string> , public optPayload: any = null) {}
    }
export class GetZentraleEingang2NichtAusgefuehrtAction implements Action {
    readonly type = GET_ZENTRALE_EINGANG2_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type IncidentsQueryZentraleEingang2Actions =
    GetZentraleEingang2Action
    | GetZentraleEingang2AusfuehrenAction
    | GetZentraleEingang2ErfolgreichAction
    | GetZentraleEingang2FehlerAction
    | GetZentraleEingang2NichtAusgefuehrtAction
    ;
