export interface TsAppVersion {
    version: string;
    name: string;
    description?: string;
    versionLong?: string;
    versionDate: string;
    gitCommitHash?: string;
    gitCommitDate?: string;
    gitTag?: string;
};
export const versions: TsAppVersion = {
    version: '0.4347.466',
    name: 'orga-app-desktop-client',
    versionDate: '2024-12-12T07:48:05.382Z',
    description: 'ORGArena ORGA App',
    gitCommitHash: 'gedbfbced',
    gitCommitDate: '2024-12-12T07:45:58.000Z',
    versionLong: '0.4347.466-gedbfbced',
    gitTag: 'v1.48.1',
};
export default versions;
