import {Action} from '@ngrx/store';

export const PAGE_STATE_RESET = '[VeranstaltungselektionModal] Page State reset';
export const SETZE_SELEKTIERTE_VERANSTALTUNG_IDS = '[VeranstaltungselektionModal] Setze selektierte Veranstaltungs-Ids';
export const NUR_VERANSTALTUNGEN_MIT_OFFENEN_INCIDENTS = '[VeranstaltungselektionModal] Nur Veranstaltungen mit offenen Incidents setzen';
export const REFRESH_DATA = '[VeranstaltungselektionModal] Veranstaltungen erneut laden';


// PAGE STATE RESET
export class PageStateResetAction implements Action {
    readonly type = PAGE_STATE_RESET;
}


// UI
export class SetzeSelektierteVeranstaltungAction implements Action {
    readonly type = SETZE_SELEKTIERTE_VERANSTALTUNG_IDS;

    constructor(public payload: Array<string>) {
    }
}

export class NurVeranstaltungenMitOffenenIncidentsSetzenAction implements Action {
    readonly type = NUR_VERANSTALTUNGEN_MIT_OFFENEN_INCIDENTS;

    constructor(public payload: boolean) {
    }
}

export class RefreshDataAction implements Action {
    readonly type = REFRESH_DATA;

    constructor() {
    }
}

/**
 * Export a type alias of all actions in this action group
 * so that reducers can easily compose action types
 */
export type Actions =
    SetzeSelektierteVeranstaltungAction
    | PageStateResetAction
    | NurVeranstaltungenMitOffenenIncidentsSetzenAction
    | RefreshDataAction
    ;
