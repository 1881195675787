import * as api from '../../../clients/incidents'; 

import {Action} from '@ngrx/store';

export const GET_ABTEILUNG_EINGANG2 = '[IncidentsModul] GetAbteilungEingang2 laden';
export const GET_ABTEILUNG_EINGANG2_AUSFUEHREN = '[IncidentsModul] GetAbteilungEingang2 ausführen';
export const GET_ABTEILUNG_EINGANG2_ERFOLGREICH = '[IncidentsModul] GetAbteilungEingang2 laden erfolgreich';
export const GET_ABTEILUNG_EINGANG2_FEHLER = '[IncidentsModul] GetAbteilungEingang2 Ladefehler';
export const GET_ABTEILUNG_EINGANG2_NICHT_AUSGEFUEHRT = '[IncidentsModul] GetAbteilungEingang2 nicht ausgeführt (evtl. Offline)';



export class GetAbteilungEingang2Action implements Action {
    readonly type = GET_ABTEILUNG_EINGANG2;
        constructor( public funktionsbereich_id:string ,  public veranstaltung_id:Array<string> , public optPayload: any = null) {}
    }
export class GetAbteilungEingang2AusfuehrenAction implements Action {
    readonly type = GET_ABTEILUNG_EINGANG2_AUSFUEHREN;
        constructor( public funktionsbereich_id:string ,  public veranstaltung_id:Array<string> , public optPayload: any = null) {}
    }
export class GetAbteilungEingang2ErfolgreichAction implements Action {
    readonly type = GET_ABTEILUNG_EINGANG2_ERFOLGREICH;
        constructor(public payload: Array<api.IncidentKopf>,  public funktionsbereich_id:string ,  public veranstaltung_id:Array<string> , public optPayload: any = null) {}
    }
export class GetAbteilungEingang2FehlerAction implements Action {
    readonly type = GET_ABTEILUNG_EINGANG2_FEHLER;
        constructor(public payload: any,  public funktionsbereich_id:string ,  public veranstaltung_id:Array<string> , public optPayload: any = null) {}
    }
export class GetAbteilungEingang2NichtAusgefuehrtAction implements Action {
    readonly type = GET_ABTEILUNG_EINGANG2_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type IncidentsQueryAbteilungEingang2Actions =
    GetAbteilungEingang2Action
    | GetAbteilungEingang2AusfuehrenAction
    | GetAbteilungEingang2ErfolgreichAction
    | GetAbteilungEingang2FehlerAction
    | GetAbteilungEingang2NichtAusgefuehrtAction
    ;
