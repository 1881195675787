import * as api from '../../../clients/incidents'; 

import {Action} from '@ngrx/store';

export const GET_ABTEILUNG_PINNWAND2 = '[IncidentsModul] GetAbteilungPinnwand2 laden';
export const GET_ABTEILUNG_PINNWAND2_AUSFUEHREN = '[IncidentsModul] GetAbteilungPinnwand2 ausführen';
export const GET_ABTEILUNG_PINNWAND2_ERFOLGREICH = '[IncidentsModul] GetAbteilungPinnwand2 laden erfolgreich';
export const GET_ABTEILUNG_PINNWAND2_FEHLER = '[IncidentsModul] GetAbteilungPinnwand2 Ladefehler';
export const GET_ABTEILUNG_PINNWAND2_NICHT_AUSGEFUEHRT = '[IncidentsModul] GetAbteilungPinnwand2 nicht ausgeführt (evtl. Offline)';



export class GetAbteilungPinnwand2Action implements Action {
    readonly type = GET_ABTEILUNG_PINNWAND2;
        constructor( public funktionsbereich_id:string ,  public veranstaltung_id:Array<string> , public optPayload: any = null) {}
    }
export class GetAbteilungPinnwand2AusfuehrenAction implements Action {
    readonly type = GET_ABTEILUNG_PINNWAND2_AUSFUEHREN;
        constructor( public funktionsbereich_id:string ,  public veranstaltung_id:Array<string> , public optPayload: any = null) {}
    }
export class GetAbteilungPinnwand2ErfolgreichAction implements Action {
    readonly type = GET_ABTEILUNG_PINNWAND2_ERFOLGREICH;
        constructor(public payload: Array<api.IncidentKopf>,  public funktionsbereich_id:string ,  public veranstaltung_id:Array<string> , public optPayload: any = null) {}
    }
export class GetAbteilungPinnwand2FehlerAction implements Action {
    readonly type = GET_ABTEILUNG_PINNWAND2_FEHLER;
        constructor(public payload: any,  public funktionsbereich_id:string ,  public veranstaltung_id:Array<string> , public optPayload: any = null) {}
    }
export class GetAbteilungPinnwand2NichtAusgefuehrtAction implements Action {
    readonly type = GET_ABTEILUNG_PINNWAND2_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type IncidentsQueryAbteilungPinnwand2Actions =
    GetAbteilungPinnwand2Action
    | GetAbteilungPinnwand2AusfuehrenAction
    | GetAbteilungPinnwand2ErfolgreichAction
    | GetAbteilungPinnwand2FehlerAction
    | GetAbteilungPinnwand2NichtAusgefuehrtAction
    ;
