import {Component, Input, OnInit} from '@angular/core';
import {Observable} from 'rxjs';
import * as fromVeranstaltungselection from './veranstaltungselektion.reducer';
import {ModalController, NavController, NavParams} from '@ionic/angular';
import * as actionsUi from './veranstaltungselektion.actions.ui';
import {select, Store} from '@ngrx/store';
import {
    getNurVeranstaltungenMitOffenenIncidents,
    getSelectedVeranstaltungsIds,
    getVeranstaltungenFehler, getVeranstaltungenLaden, getVeranstaltungenList
} from './veranstaltungselektion.selectors';
import {ListInputItem} from '../../shared/sopi-list/sopi-list.model';


@Component({
    selector: 'app-veranstaltungselektion',
    templateUrl: './veranstaltungselektion.page.html',
    styleUrls: ['./veranstaltungselektion.page.scss'],
})
export class VeranstaltungselektionPage implements OnInit {
    @Input() untertitel: string;

    veranstaltungenList$: Observable<Array<ListInputItem>>;
    veranstaltungenLaden$: Observable<boolean>;
    veranstaltungenFehler$: Observable<boolean>;
    selectedVeranstaltungIds$: Observable<Array<string>>;

    nurMitIncidents$: Observable<boolean>;

    constructor(public navCtrl: NavController,
                public navParams: NavParams,
                private modalCtrl: ModalController,
                private store: Store<fromVeranstaltungselection.PageState>) {

    }

    ngOnInit() {
        this.veranstaltungenList$ = this.store.pipe(select(getVeranstaltungenList));
        this.veranstaltungenLaden$ = this.store.pipe(select(getVeranstaltungenLaden));
        this.veranstaltungenFehler$ = this.store.pipe(select(getVeranstaltungenFehler));
        this.selectedVeranstaltungIds$ = this.store.pipe(select(getSelectedVeranstaltungsIds));

        this.nurMitIncidents$ = this.store.pipe(select(getNurVeranstaltungenMitOffenenIncidents));
    }

    ionViewWillEnter() {
        this.viewWillEnterPage().then();
    }

    async viewWillEnterPage() {
        await this.store.dispatch(new actionsUi.PageStateResetAction());
    }


    async ok() {
        this.selectedVeranstaltungIds$.subscribe(async (ids) => {
            const data = {ausgewaehlteVeranstaltungIds: ids};
            await this.modalCtrl.dismiss(data);
        }).unsubscribe();
    }

    async abbrechen() {
        await this.modalCtrl.dismiss();
    }

    async refreshData() {
        await this.store.dispatch(new actionsUi.RefreshDataAction());
    }

    async mitIncidentsChecked($event) {
        if ($event.detail !== undefined && $event.detail !== null && $event.detail.checked !== undefined && $event.detail.checked !== null) {
            await this.store.dispatch(new actionsUi.NurVeranstaltungenMitOffenenIncidentsSetzenAction($event.detail.checked));
            await this.store.dispatch(new actionsUi.RefreshDataAction());
        }
    }

    async selectedVeranstaltungIdsChanged(ids: Array<string>) {
        await this.store.dispatch(new actionsUi.SetzeSelektierteVeranstaltungAction(ids));
    }
}
