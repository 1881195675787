import * as api from '../../../clients/incidents'; 

import {Action} from '@ngrx/store';

export const GET_INCIDENTS_COUNTER2 = '[IncidentsModul] GetIncidentsCounter2 laden';
export const GET_INCIDENTS_COUNTER2_AUSFUEHREN = '[IncidentsModul] GetIncidentsCounter2 ausführen';
export const GET_INCIDENTS_COUNTER2_ERFOLGREICH = '[IncidentsModul] GetIncidentsCounter2 laden erfolgreich';
export const GET_INCIDENTS_COUNTER2_FEHLER = '[IncidentsModul] GetIncidentsCounter2 Ladefehler';
export const GET_INCIDENTS_COUNTER2_NICHT_AUSGEFUEHRT = '[IncidentsModul] GetIncidentsCounter2 nicht ausgeführt (evtl. Offline)';



export class GetIncidentsCounter2Action implements Action {
    readonly type = GET_INCIDENTS_COUNTER2;
        constructor( public veranstaltung_id:Array<string> , public optPayload: any = null) {}
    }
export class GetIncidentsCounter2AusfuehrenAction implements Action {
    readonly type = GET_INCIDENTS_COUNTER2_AUSFUEHREN;
        constructor( public veranstaltung_id:Array<string> , public optPayload: any = null) {}
    }
export class GetIncidentsCounter2ErfolgreichAction implements Action {
    readonly type = GET_INCIDENTS_COUNTER2_ERFOLGREICH;
        constructor(public payload: api.IncidentsCounter,  public veranstaltung_id:Array<string> , public optPayload: any = null) {}
    }
export class GetIncidentsCounter2FehlerAction implements Action {
    readonly type = GET_INCIDENTS_COUNTER2_FEHLER;
        constructor(public payload: any,  public veranstaltung_id:Array<string> , public optPayload: any = null) {}
    }
export class GetIncidentsCounter2NichtAusgefuehrtAction implements Action {
    readonly type = GET_INCIDENTS_COUNTER2_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type IncidentsQueryIncidentsCounter2Actions =
    GetIncidentsCounter2Action
    | GetIncidentsCounter2AusfuehrenAction
    | GetIncidentsCounter2ErfolgreichAction
    | GetIncidentsCounter2FehlerAction
    | GetIncidentsCounter2NichtAusgefuehrtAction
    ;
