import {LOCALE_ID, NgModule} from '@angular/core';
import {BrowserModule, Title} from '@angular/platform-browser';
import {RouteReuseStrategy} from '@angular/router';

import {IonicModule, IonicRouteStrategy} from '@ionic/angular';
import {SplashScreen} from '@ionic-native/splash-screen/ngx';
import {StatusBar} from '@ionic-native/status-bar/ngx';

import {AppComponent} from './app.component';
import {AppRoutingModule} from './app-routing.module';
import {StoreModule} from '@ngrx/store';
import {metaReducers, reducers} from './reducers';
import {StoreDevtoolsModule} from '@ngrx/store-devtools';
import {EffectsModule} from '@ngrx/effects';
import {AppEffects} from './app.effects';
import {HttpClient, HttpClientModule} from '@angular/common/http';
import {WebsocketEffects} from './websocket.effects';
import localeDeDe from '@angular/common/locales/de';
import {AppInsightsService, ApplicationInsightsModule} from '@markpieszak/ng-application-insights';
import {registerLocaleData} from '@angular/common';
import {SettingsModule} from '@orga-app/settings';
import {LanguageModule} from '@softwarepioniere/language';
import {FunktionsbereichsauswahlPageModule} from './verwaltung/funktionsbereichsauswahl/funktionsbereichsauswahl.module';
import {FunktionsbereichEntfernenPageModule} from './verwaltung/funktionsbereich-entfernen/funktionsbereich-entfernen.module';
import {SopiScrollModule} from '../sopi/components/sopi-scroll/sopi-scroll.module';
import {ChecklisteEntfernenPageModule} from './verwaltung/checkliste-entfernen/checkliste-entfernen.module';
import {ChecklistenauswahlPageModule} from './verwaltung/checklistenauswahl/checklistenauswahl.module';
import {BenutzerauswahlPageModule} from './verwaltung/benutzerauswahl/benutzerauswahl.module';
import {FunktionsbereichLoeschenPageModule} from './verwaltung/funktionsbereich-loeschen/funktionsbereich-loeschen.module';
import {AufgabePageModule} from './verwaltung/aufgabe/aufgabe.module';
import {SopiComponentsModule} from './shared/sopi-components/sopi-components.module';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {VeranstaltungLoeschenPageModule} from './verwaltung/veranstaltung-loeschen/veranstaltung-loeschen.module';
import {ChecklisteLoeschenPageModule} from './verwaltung/checkliste-loeschen/checkliste-loeschen.module';
import {ModalDokumentLoeschenPageModule} from './verwaltung/dokumentverwaltung/modal-dokument-loeschen/modal-dokument-loeschen.module';
import {environment} from '../environments/environment';
import {WebsocketModule} from '@orga-app/websocket';
import {WebsocketInitEffects} from './websocket.init.effects';
// import {Drivers, Storage} from '@ionic/storage';
// import {IonicStorageModule} from '@ionic/storage-angular';
import {NaviModule} from './navi/navi.module';
import {AppStateModule} from './operativ/app-state/app.state.modules';
import {VeranstaltungKopierenPageModule} from './verwaltung/veranstaltung-kopieren/veranstaltung-kopieren.module';
import {AppInsightEffects} from './app-insight.effects';
import {TranslateCompiler, TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {TranslateMessageFormatCompiler} from 'ngx-translate-messageformat-compiler';
import {MultiTranslateHttpLoader} from 'ngx-translate-multi-http-loader';
import {JsonEditorPageModule} from './mandantenverwaltung/modale/json-editor/json-editor.module';
import {HinweisMeldungPageModule} from './shared/hinweis-meldung/hinweis-meldung.module';
import {BenutzerverwaltungBenutzerInfoEffects} from '../client-ngrx/benutzerverwaltung';
import {MarkdownModule} from 'ngx-markdown';
import {DragDropModule} from '@angular/cdk/drag-drop';
import {RollenselektionPageModule} from './verwaltung/rollenselektion/rollenselektion.module';

import {FileOpener} from '@ionic-native/file-opener/ngx';
import {DocumentViewer} from '@ionic-native/document-viewer/ngx';
import {FilePath} from '@ionic-native/file-path/ngx';
import {File} from '@ionic-native/file/ngx';
import {AufgabebemerkungPageModule} from './operativ/aufgabebemerkung/aufgabebemerkung.module';
import {FehlerbeschreibungPageModule} from './operativ/fehlerbeschreibung/fehlerbeschreibung.module';
import {BildVorschauModule} from '../sopi/components/bild-vorschau/bild-vorschau.module';
import {AuthModule, AuthTypes} from '@orga-app/auth';
import {MandantModule} from '@orga-app/mandant';
import {ClientsModule} from '@orga-app/clients';
import {MandantRoutingModule} from './mandant/mandant-routing.module';
import {MyModule} from '@orga-app/providers';
import {EnvModule} from '@orga-app/env/env-module';
import {HomeComponent} from './home/home.component';
import {AppEffectsDesktopClient} from './app.effects-desktop-client';
import {NeuenIncidentPageModule} from './operativ/incident/incident.module';
import {ImpressumPageModule} from './impressum/impressum.module';
import {PipesModule} from '../sopi/pipes/pipes.module';
import {FeldOptionPageModule} from './mandantenverwaltung/modale/feld-option/feld-option.module';
import {ApiMandantClearCacheEffects} from '../client-ngrx/api';
import {DisplaysteuerungPageModule} from './verwaltung/displaysteuerung/displaysteuerung.module';
import {TriggerauswahlPageModule} from './verwaltung/displaysteuerung/triggerauswahl/triggerauswahl.module';
import {QschecksControllingSaisonFilterPageModule} from './controlling/qschecks/qschecks-controlling-saison-filter/qschecks-controlling-saison-filter.module';
import {VeranstaltungstypAuswahlPageModule} from './modale/veranstaltungstyp-auswahl/veranstaltungstyp-auswahl.module';
import {AufgabenBerechtigungenModalModule} from './verwaltung/aufgaben-berechtigungen/aufgaben-berechtigungen-modal/aufgaben-berechtigungen-modal.module';
import {RolleLoeschenPageModule} from './verwaltung/rolle-loeschen/rolle-loeschen.module';
import {VeranstaltungselektionPageModule} from './operativ/veranstaltungsselection/veranstaltungselektion.module';


export function HttpLoaderFactory(http: HttpClient) {
    return new MultiTranslateHttpLoader(http, [
        {prefix: './assets/i18n/', suffix: '.json'},
        {prefix: './assets/i18n/update/', suffix: '.json'},
    ]);
}

registerLocaleData(localeDeDe);

@NgModule({
    declarations: [AppComponent, HomeComponent],
    // entryComponents: [HomeComponent],
    imports: [
        EnvModule.forRoot(environment),
        BrowserModule,
        HttpClientModule,
        BrowserAnimationsModule,
        DragDropModule,
        IonicModule.forRoot({
            mode: 'md',
            animated: true
        }),
        // IonicStorageModule.forRoot({
        //     name: '_ionickv',
        //     driverOrder: [Drivers.IndexedDB, Drivers.LocalStorage]
        // }),

        // IonicStorageModule.forRoot(),
        // IonicStorageModule.forRoot({
        //     name: '_ionicstorage',
        //     driverOrder: ['indexeddb', 'sqlite', 'websql']
        // }),

        LanguageModule,


        AppRoutingModule,

        AuthModule.forRoot({
            loginRoute: environment.loginRoute,
            azureAd: environment.azureAd,
            debug: environment.debug,
            type: AuthTypes.aad2,
            redirectUri: environment.homeRoute,
            redirectUriFromOrigin: true,
            tenantMappings: environment.azureAd.tenantMappings
        }),

        AppStateModule.forRoot(),
        MandantModule.forRoot(environment.appConfig.apiUrl, 'mandanten-auswahl'),
        MyModule.forRoot(environment.appConfig.apiUrl),
        WebsocketModule.forRoot(environment.appConfig.wsEndpoint),
        ClientsModule.forRoot(environment.appConfig.apiUrl),

        SopiComponentsModule.forRoot(),

        SettingsModule.forRoot(environment.appConfig.themeUrl, environment.appConfig.themeType),
        SopiScrollModule,
        MarkdownModule.forRoot(),
        StoreModule.forRoot(reducers, {
            metaReducers,
            runtimeChecks: {
                strictStateImmutability: true,
                strictActionImmutability: true
            }
        }),

        environment.debug ? StoreDevtoolsModule.instrument({
            name: 'ORGA App ' + environment.appTitle,
            maxAge: 250, // Retains last 25 states
            logOnly: environment.debug, // Restrict extension to log-only mode
        }) : [],

        EffectsModule.forRoot([
            AppInsightEffects,
            AppEffects,
            AppEffectsDesktopClient,
            WebsocketInitEffects,
            WebsocketEffects,
            BenutzerverwaltungBenutzerInfoEffects,
            ApiMandantClearCacheEffects
        ]),
        TranslateModule.forRoot({
            compiler: {
                provide: TranslateCompiler,
                useClass: TranslateMessageFormatCompiler
            },
            loader: {
                provide: TranslateLoader,
                useFactory: (HttpLoaderFactory),
                deps: [HttpClient]
            }
        }),
        ApplicationInsightsModule.forRoot({
            appId: 'desktop-client',
            instrumentationKey: environment.appConfig.appInsightsInstrumentationKey
        }),
        NaviModule.forRoot(),


        MandantRoutingModule,

        // Modale
        FunktionsbereichsauswahlPageModule.forRoot(),
        FunktionsbereichEntfernenPageModule.forRoot(),
        FunktionsbereichLoeschenPageModule.forRoot(),
        ChecklisteEntfernenPageModule.forRoot(),
        ChecklistenauswahlPageModule.forRoot(),
        ChecklisteLoeschenPageModule.forRoot(),
        BenutzerauswahlPageModule.forRoot(),
        AufgabePageModule.forRoot(),
        VeranstaltungLoeschenPageModule.forRoot(),
        VeranstaltungKopierenPageModule.forRoot(),
        ModalDokumentLoeschenPageModule.forRoot(),
        JsonEditorPageModule.forRoot(),
        FeldOptionPageModule.forRoot(),
        HinweisMeldungPageModule.forRoot(),
        RollenselektionPageModule.forRoot(),
        RolleLoeschenPageModule.forRoot(),
        AufgabebemerkungPageModule.forRoot(),
        FehlerbeschreibungPageModule.forRoot(),
        BildVorschauModule.forRoot(),
        NeuenIncidentPageModule.forRoot(),
        ImpressumPageModule.forRoot(),
        DisplaysteuerungPageModule.forRoot(),
        TriggerauswahlPageModule.forRoot(),
        QschecksControllingSaisonFilterPageModule.forRoot(),
        VeranstaltungstypAuswahlPageModule.forRoot(),
        AufgabenBerechtigungenModalModule.forRoot(),
        VeranstaltungselektionPageModule.forRoot(),
        PipesModule,
        // FunktionsbereicheChecklistenPageModule.forRoot()
    ],
    providers: [
        StatusBar,
        SplashScreen,

        File,
        FilePath,
        FileOpener,
        DocumentViewer,

        // Aus App
        AppInsightsService,
        {provide: RouteReuseStrategy, useClass: IonicRouteStrategy},
        {provide: LOCALE_ID, useValue: 'de-DE'},
        Title
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
}
