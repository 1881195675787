import {CUSTOM_ELEMENTS_SCHEMA, ModuleWithProviders, NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule} from '@angular/forms';
import {RouterModule, Routes} from '@angular/router';

import {IonicModule} from '@ionic/angular';

import {VeranstaltungselektionPage} from './veranstaltungselektion.page';
import {ActionReducer, StoreModule} from '@ngrx/store';
import {reducers} from './veranstaltungselektion.reducer';
import {EffectsModule} from '@ngrx/effects';
import {SopiScrollModule} from '../../../sopi/components/sopi-scroll/sopi-scroll.module';
import {VeranstaltungselektionEffectsPage} from './veranstaltungselektion.effects.ui';
import {SopiComponentsModule} from 'src/app/shared/sopi-components/sopi-components.module';
import {IncidentsVeranstaltungenEffects} from '../../../client-ngrx/incidents';
import {LocalStorageConfig, localStorageSync} from 'ngrx-store-localstorage';

const routes: Routes = [
    {
        path: '',
        component: VeranstaltungselektionPage
    }
];

export function sessionStorage(reducer: ActionReducer<any>): ActionReducer<any> {

    const config: LocalStorageConfig = {
        keys: [
            'veranstaltungselektionPage'
        ],
        rehydrate: true,
        removeOnUndefined: true
    };

    return localStorageSync(config)(reducer);
}

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        IonicModule,
        RouterModule.forChild(routes),
        StoreModule.forFeature('VeranstaltungselektionPage', reducers, {metaReducers: [sessionStorage]}),
        EffectsModule.forFeature([
            VeranstaltungselektionEffectsPage,
            IncidentsVeranstaltungenEffects
        ]),
        SopiScrollModule,
        SopiComponentsModule.forRoot()
    ],
    declarations: [VeranstaltungselektionPage],
    entryComponents: [VeranstaltungselektionPage],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class VeranstaltungselektionPageModule {
    public static forRoot(): ModuleWithProviders {
        return {
            ngModule: VeranstaltungselektionPageModule,
        };
    }
}
