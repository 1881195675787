import {Action, ActionReducerMap} from '@ngrx/store';
import {Abteilung, IncidentDetail, Veranstaltung} from '../../../clients/incidents';
import {GET_INCIDENT_DETAIL, GET_INCIDENT_DETAIL_ERFOLGREICH, GET_INCIDENT_DETAIL_FEHLER, GET_VERANSTALTUNGEN, GET_VERANSTALTUNGEN_ERFOLGREICH, GET_VERANSTALTUNGEN_FEHLER, GetIncidentDetailErfolgreichAction, GetVeranstaltungenErfolgreichAction} from '../../../client-ngrx/incidents';
import {SETZE_INCIDENT_ABTEILUNG, SETZE_INCIDENT_BESCHREIBUNG, SETZE_INCIDENT_DELEGATION_ROLLE, SETZE_INCIDENT_DETAIL_ID, SETZE_INCIDENT_ORT, SETZE_INCIDENT_TITEL, SETZE_INCIDENT_ZENTRALE, SETZE_VERANSTALTUNGS_ID, SetzeIncidentAbteilungAction, SetzeIncidentBeschreibungAction, SetzeIncidentDelegationRolleAction, SetzeIncidentDetailIdAction, SetzeIncidentOrtAction, SetzeIncidentTitelAction, SetzeVeranstaltungsIdAction} from './incident.actions.ui';
import * as actionsUi from './incident.actions.ui';

export interface ModuleState {
    page: PageState;
}

export const reducers: ActionReducerMap<ModuleState> = {
    page: pageReducer
};


export interface PageState {
    incidentId: string;

    incidentDetailOriginal: IncidentDetail;
    incidentDetail: IncidentDetail;
    incidentDetailLaden: boolean;
    incidentDetailFehler: boolean;

    veranstaltungen: Array<Veranstaltung>;
    veranstaltungenLaden: boolean;
    veranstaltungenFehler: boolean;

    abteilungSelected: Abteilung;

}

export const initialPageState: PageState = {
    incidentId: '',

    incidentDetailOriginal: null,
    incidentDetail: null,
    incidentDetailLaden: false,
    incidentDetailFehler: false,

    veranstaltungen: [],
    veranstaltungenLaden: false,
    veranstaltungenFehler: false,

    abteilungSelected: null
};


export function pageReducer(state = initialPageState, action: Action): PageState {
    switch (action.type) {
        // APP RESET
        case actionsUi.PAGE_STATE_RESET: {
            return Object.assign({}, state, <PageState>initialPageState, <PageState> {
                incidentId: state.incidentId
            });
        }

        // IncidentDetail
        case GET_INCIDENT_DETAIL: {
            return Object.assign({}, state, <PageState>{
                incidentDetailLaden: true,
                incidentDetailFehler: false
            });
        }

        case GET_INCIDENT_DETAIL_ERFOLGREICH: {
            const a = <GetIncidentDetailErfolgreichAction> action;

            const incidentDetailOriginal = Object.assign({}, a.payload);

            return Object.assign({}, state, <PageState> {
                incidentDetailLaden: false,
                incidentDetailFehler: false,
                incidentDetail: a.payload,
                incidentDetailOriginal
            });
        }

        case GET_INCIDENT_DETAIL_FEHLER: {
            return Object.assign({}, state, <PageState>{
                incidentDetailLaden: false,
                incidentDetailFehler: true,
                incidentDetail: null
            });
        }


        // Veranstaltungen
        case GET_VERANSTALTUNGEN: {
            return Object.assign({}, state, <PageState>{
                veranstaltungenLaden: true,
                veranstaltungenFehler: false
            });
        }

        case GET_VERANSTALTUNGEN_ERFOLGREICH: {
            const a = <GetVeranstaltungenErfolgreichAction> action;

            return Object.assign({}, state, <PageState> {
                veranstaltungenLaden: false,
                veranstaltungenFehler: false,
                veranstaltungen: a.payload
            });
        }

        case GET_VERANSTALTUNGEN_FEHLER: {
            return Object.assign({}, state, <PageState>{
                veranstaltungenLaden: false,
                veranstaltungenFehler: true,
                veranstaltungen: []
            });
        }


        // Detail-Properties setzen
        case SETZE_INCIDENT_DETAIL_ID: {
            const a = <SetzeIncidentDetailIdAction>action;

            return Object.assign({}, state, <PageState> {
                incidentId: a.payload,
                incidentDetail: {},
                incidentDetailOriginal: null
            });
        }

        case SETZE_INCIDENT_TITEL: {
            const a = <SetzeIncidentTitelAction>action;

            const incidentDetail = Object.assign({}, state.incidentDetail, <IncidentDetail>{
                titel: a.payload
            });

            return Object.assign({}, state, <PageState> {
                incidentDetail
            });
        }

        case SETZE_INCIDENT_BESCHREIBUNG: {
            const a = <SetzeIncidentBeschreibungAction>action;

            const incidentDetail = Object.assign({}, state.incidentDetail, <IncidentDetail>{
                beschreibung: a.payload
            });

            return Object.assign({}, state, <PageState> {
                incidentDetail
            });
        }

        case SETZE_INCIDENT_ORT: {
            const a = <SetzeIncidentOrtAction>action;

            const incidentDetail = Object.assign({}, state.incidentDetail, <IncidentDetail>{
                ort: a.payload
            });

            return Object.assign({}, state, <PageState> {
                incidentDetail
            });
        }

        case SETZE_VERANSTALTUNGS_ID: {
            const a = <SetzeVeranstaltungsIdAction>action;

            const incidentDetail = Object.assign({}, state.incidentDetail, <IncidentDetail>{
                veranstaltung_id: a.payload
            });

            return Object.assign({}, state, <PageState> {
                incidentDetail
            });
        }

        case SETZE_INCIDENT_ABTEILUNG: {
            const a = <SetzeIncidentAbteilungAction>action;

            let incidentDetail: IncidentDetail = Object.assign({}, state.incidentDetail, {
                ist_verantwortlichkeit_abteilung: false,
                verantwortlichkeit_funktionsbereich_id: null,
                delegation_rolle: null,
                delegation_funktionsbereich_id: null,
                delegation_rolle_id: null
            });
            if (a.payload !== null && a.payload !== undefined) {
                incidentDetail = Object.assign({}, incidentDetail, {
                    ist_verantwortlichkeit_zentrale: false,
                    ist_verantwortlichkeit_abteilung: true,
                    verantwortlichkeit_funktionsbereich_id: a.payload.funktionsbereich_id
                });
            }

            return Object.assign({}, state, <PageState> {
                abteilungSelected: a.payload,
                incidentDetail
            });
        }

        case SETZE_INCIDENT_ZENTRALE: {
            const incidentDetail = Object.assign({}, state.incidentDetail, <IncidentDetail>{
                ist_verantwortlichkeit_zentrale: true,
                ist_verantwortlichkeit_abteilung: false,
                verantwortlichkeit_funktionsbereich_id: null,
                delegation_rolle: null,
                delegation_funktionsbereich_id: null,
                delegation_rolle_id: null
            });
            return Object.assign({}, state, <PageState> {
                abteilungSelected: null,
                incidentDetail
            });
        }

        case SETZE_INCIDENT_DELEGATION_ROLLE: {
            const a = <SetzeIncidentDelegationRolleAction>action;

            const incidentDetail = Object.assign({}, state.incidentDetail, <IncidentDetail>{
                delegation_rolle: a.payload,
                delegation_rolle_id: a.payload.rolle_id,
                delegation_funktionsbereich_id: a.payload.funktionsbereich_id
            });

            return Object.assign({}, state, <PageState> {
                incidentDetail
            });
        }

        default: {
            return state;
        }
    }
}
