import * as api from '../../../clients/incidents'; 

import {Action} from '@ngrx/store';

export const GET_VERANSTALTUNGEN = '[IncidentsModul] GetVeranstaltungen laden';
export const GET_VERANSTALTUNGEN_AUSFUEHREN = '[IncidentsModul] GetVeranstaltungen ausführen';
export const GET_VERANSTALTUNGEN_ERFOLGREICH = '[IncidentsModul] GetVeranstaltungen laden erfolgreich';
export const GET_VERANSTALTUNGEN_FEHLER = '[IncidentsModul] GetVeranstaltungen Ladefehler';
export const GET_VERANSTALTUNGEN_NICHT_AUSGEFUEHRT = '[IncidentsModul] GetVeranstaltungen nicht ausgeführt (evtl. Offline)';



export class GetVeranstaltungenAction implements Action {
    readonly type = GET_VERANSTALTUNGEN;
        constructor( public nur_aktive:boolean ,  public nur_mit_incidents:boolean , public optPayload: any = null) {}
    }
export class GetVeranstaltungenAusfuehrenAction implements Action {
    readonly type = GET_VERANSTALTUNGEN_AUSFUEHREN;
        constructor( public nur_aktive:boolean ,  public nur_mit_incidents:boolean , public optPayload: any = null) {}
    }
export class GetVeranstaltungenErfolgreichAction implements Action {
    readonly type = GET_VERANSTALTUNGEN_ERFOLGREICH;
        constructor(public payload: Array<api.Veranstaltung>,  public nur_aktive:boolean ,  public nur_mit_incidents:boolean , public optPayload: any = null) {}
    }
export class GetVeranstaltungenFehlerAction implements Action {
    readonly type = GET_VERANSTALTUNGEN_FEHLER;
        constructor(public payload: any,  public nur_aktive:boolean ,  public nur_mit_incidents:boolean , public optPayload: any = null) {}
    }
export class GetVeranstaltungenNichtAusgefuehrtAction implements Action {
    readonly type = GET_VERANSTALTUNGEN_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type IncidentsQueryVeranstaltungenActions =
    GetVeranstaltungenAction
    | GetVeranstaltungenAusfuehrenAction
    | GetVeranstaltungenErfolgreichAction
    | GetVeranstaltungenFehlerAction
    | GetVeranstaltungenNichtAusgefuehrtAction
    ;
