import * as api from '../../../clients/incidents'; 

import {Action} from '@ngrx/store';

export const GET_ZENTRALE_PINNWAND2 = '[IncidentsModul] GetZentralePinnwand2 laden';
export const GET_ZENTRALE_PINNWAND2_AUSFUEHREN = '[IncidentsModul] GetZentralePinnwand2 ausführen';
export const GET_ZENTRALE_PINNWAND2_ERFOLGREICH = '[IncidentsModul] GetZentralePinnwand2 laden erfolgreich';
export const GET_ZENTRALE_PINNWAND2_FEHLER = '[IncidentsModul] GetZentralePinnwand2 Ladefehler';
export const GET_ZENTRALE_PINNWAND2_NICHT_AUSGEFUEHRT = '[IncidentsModul] GetZentralePinnwand2 nicht ausgeführt (evtl. Offline)';



export class GetZentralePinnwand2Action implements Action {
    readonly type = GET_ZENTRALE_PINNWAND2;
        constructor( public veranstaltung_id:Array<string> , public optPayload: any = null) {}
    }
export class GetZentralePinnwand2AusfuehrenAction implements Action {
    readonly type = GET_ZENTRALE_PINNWAND2_AUSFUEHREN;
        constructor( public veranstaltung_id:Array<string> , public optPayload: any = null) {}
    }
export class GetZentralePinnwand2ErfolgreichAction implements Action {
    readonly type = GET_ZENTRALE_PINNWAND2_ERFOLGREICH;
        constructor(public payload: Array<api.IncidentKopf>,  public veranstaltung_id:Array<string> , public optPayload: any = null) {}
    }
export class GetZentralePinnwand2FehlerAction implements Action {
    readonly type = GET_ZENTRALE_PINNWAND2_FEHLER;
        constructor(public payload: any,  public veranstaltung_id:Array<string> , public optPayload: any = null) {}
    }
export class GetZentralePinnwand2NichtAusgefuehrtAction implements Action {
    readonly type = GET_ZENTRALE_PINNWAND2_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type IncidentsQueryZentralePinnwand2Actions =
    GetZentralePinnwand2Action
    | GetZentralePinnwand2AusfuehrenAction
    | GetZentralePinnwand2ErfolgreichAction
    | GetZentralePinnwand2FehlerAction
    | GetZentralePinnwand2NichtAusgefuehrtAction
    ;
