import {Injectable} from '@angular/core';
import {Actions, Effect, ofType} from '@ngrx/effects';
import {Observable} from 'rxjs';
import {Action, select, Store} from '@ngrx/store';
import {map, withLatestFrom} from 'rxjs/operators';
import * as actionsUi from './veranstaltungselektion.actions.ui';
import {PageState} from './veranstaltungselektion.reducer';
import {getNurVeranstaltungenMitOffenenIncidents} from './veranstaltungselektion.selectors';
import {GetVeranstaltungenAction} from '../../../client-ngrx/incidents';


@Injectable()
export class VeranstaltungselektionEffectsPage {

    @Effect()
    pageResetVeranstaltungsselektion$: Observable<Action> = this.actions$.pipe(
        ofType(actionsUi.PAGE_STATE_RESET, actionsUi.REFRESH_DATA)
        , withLatestFrom(this.store.pipe(select(getNurVeranstaltungenMitOffenenIncidents)))
        , map(([x, nurMitIncidents]) => {
            return new GetVeranstaltungenAction(false, nurMitIncidents);
        })
    );

    constructor(private actions$: Actions,
                private store: Store<PageState>) {
    }
}
