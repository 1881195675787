

import {Action} from '@ngrx/store';

export const GET_VERANSTALTUNG_PRUEFBERICHT_EMPTY_XML_TEMPLATE = '[VeranstaltungenModul] GetVeranstaltungPruefberichtEmptyXmlTemplate laden';
export const GET_VERANSTALTUNG_PRUEFBERICHT_EMPTY_XML_TEMPLATE_AUSFUEHREN = '[VeranstaltungenModul] GetVeranstaltungPruefberichtEmptyXmlTemplate ausführen';
export const GET_VERANSTALTUNG_PRUEFBERICHT_EMPTY_XML_TEMPLATE_ERFOLGREICH = '[VeranstaltungenModul] GetVeranstaltungPruefberichtEmptyXmlTemplate laden erfolgreich';
export const GET_VERANSTALTUNG_PRUEFBERICHT_EMPTY_XML_TEMPLATE_FEHLER = '[VeranstaltungenModul] GetVeranstaltungPruefberichtEmptyXmlTemplate Ladefehler';
export const GET_VERANSTALTUNG_PRUEFBERICHT_EMPTY_XML_TEMPLATE_NICHT_AUSGEFUEHRT = '[VeranstaltungenModul] GetVeranstaltungPruefberichtEmptyXmlTemplate nicht ausgeführt (evtl. Offline)';



export class GetVeranstaltungPruefberichtEmptyXmlTemplateAction implements Action {
    readonly type = GET_VERANSTALTUNG_PRUEFBERICHT_EMPTY_XML_TEMPLATE;
        constructor(public optPayload: any = null) {}
    }
export class GetVeranstaltungPruefberichtEmptyXmlTemplateAusfuehrenAction implements Action {
    readonly type = GET_VERANSTALTUNG_PRUEFBERICHT_EMPTY_XML_TEMPLATE_AUSFUEHREN;
        constructor(public optPayload: any = null) {}
    }
export class GetVeranstaltungPruefberichtEmptyXmlTemplateErfolgreichAction implements Action {
    readonly type = GET_VERANSTALTUNG_PRUEFBERICHT_EMPTY_XML_TEMPLATE_ERFOLGREICH;
        constructor(public optPayload: any = null) {}
    }
export class GetVeranstaltungPruefberichtEmptyXmlTemplateFehlerAction implements Action {
    readonly type = GET_VERANSTALTUNG_PRUEFBERICHT_EMPTY_XML_TEMPLATE_FEHLER;
        constructor(public payload: any, public optPayload: any = null) {}
    }
export class GetVeranstaltungPruefberichtEmptyXmlTemplateNichtAusgefuehrtAction implements Action {
    readonly type = GET_VERANSTALTUNG_PRUEFBERICHT_EMPTY_XML_TEMPLATE_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type VeranstaltungenQueryVeranstaltungPruefberichtEmptyXmlTemplateActions =
    GetVeranstaltungPruefberichtEmptyXmlTemplateAction
    | GetVeranstaltungPruefberichtEmptyXmlTemplateAusfuehrenAction
    | GetVeranstaltungPruefberichtEmptyXmlTemplateErfolgreichAction
    | GetVeranstaltungPruefberichtEmptyXmlTemplateFehlerAction
    | GetVeranstaltungPruefberichtEmptyXmlTemplateNichtAusgefuehrtAction
    ;
