import * as api from '../../../clients/incidents'; 

import {Action} from '@ngrx/store';

export const GET_ABTEILUNG_LISTE2 = '[IncidentsModul] GetAbteilungListe2 laden';
export const GET_ABTEILUNG_LISTE2_AUSFUEHREN = '[IncidentsModul] GetAbteilungListe2 ausführen';
export const GET_ABTEILUNG_LISTE2_ERFOLGREICH = '[IncidentsModul] GetAbteilungListe2 laden erfolgreich';
export const GET_ABTEILUNG_LISTE2_FEHLER = '[IncidentsModul] GetAbteilungListe2 Ladefehler';
export const GET_ABTEILUNG_LISTE2_NICHT_AUSGEFUEHRT = '[IncidentsModul] GetAbteilungListe2 nicht ausgeführt (evtl. Offline)';



export class GetAbteilungListe2Action implements Action {
    readonly type = GET_ABTEILUNG_LISTE2;
        constructor( public funktionsbereich_id:string ,  public veranstaltung_id:Array<string> , public optPayload: any = null) {}
    }
export class GetAbteilungListe2AusfuehrenAction implements Action {
    readonly type = GET_ABTEILUNG_LISTE2_AUSFUEHREN;
        constructor( public funktionsbereich_id:string ,  public veranstaltung_id:Array<string> , public optPayload: any = null) {}
    }
export class GetAbteilungListe2ErfolgreichAction implements Action {
    readonly type = GET_ABTEILUNG_LISTE2_ERFOLGREICH;
        constructor(public payload: api.IncidentsListResponse,  public funktionsbereich_id:string ,  public veranstaltung_id:Array<string> , public optPayload: any = null) {}
    }
export class GetAbteilungListe2FehlerAction implements Action {
    readonly type = GET_ABTEILUNG_LISTE2_FEHLER;
        constructor(public payload: any,  public funktionsbereich_id:string ,  public veranstaltung_id:Array<string> , public optPayload: any = null) {}
    }
export class GetAbteilungListe2NichtAusgefuehrtAction implements Action {
    readonly type = GET_ABTEILUNG_LISTE2_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type IncidentsQueryAbteilungListe2Actions =
    GetAbteilungListe2Action
    | GetAbteilungListe2AusfuehrenAction
    | GetAbteilungListe2ErfolgreichAction
    | GetAbteilungListe2FehlerAction
    | GetAbteilungListe2NichtAusgefuehrtAction
    ;
