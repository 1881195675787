import {createFeatureSelector, createSelector} from '@ngrx/store';
import {ModuleState, PageState} from './incident.reducer';
import {Abteilung, IncidentDetail, IncidentManagementBenutzerZugriff, Rolle} from '../../../clients/incidents';
import {getZugriffIncidents} from '../../navi/navi.reducer';

export const getModuleState = createFeatureSelector<ModuleState>('IncidentPage');

export const getPageState = createSelector(getModuleState, (state: ModuleState) => state.page);

export const getSelectedIncidentId = createSelector(getPageState, (state: PageState) => state.incidentId);

export const getIncidentDetailOriginal = createSelector(getPageState, (state: PageState) => state.incidentDetailOriginal);
export const getIncidentDetail = createSelector(getPageState, (state: PageState) => state.incidentDetail);
export const getIncidentDetailLaden = createSelector(getPageState, (state: PageState) => state.incidentDetailLaden);

export const getAbteilungSelected = createSelector(getPageState, (state: PageState) => state.abteilungSelected);

export const getDelegationRollen = createSelector(getAbteilungSelected, getIncidentDetail, getZugriffIncidents,
    (abteilung: Abteilung, detail: IncidentDetail, zugriff: IncidentManagementBenutzerZugriff): Array<Rolle> => {
    const retListe: Array<Rolle> = [];

    if (abteilung !== undefined && abteilung !== null && detail !== undefined && detail !== null && detail.ist_verantwortlichkeit_abteilung) {
        retListe.push(...abteilung.rollen);
    }

    // Rollen für die Zentrale
    if (detail !== undefined && detail !== null && detail.ist_verantwortlichkeit_zentrale && zugriff !== undefined && zugriff !== null) {
        retListe.push(...zugriff.anlegen.rollen_zentrale);
    }

    // console.log('retListe: ', retListe);

    return retListe;
});

export const getVeranstaltungen = createSelector(getPageState, (state: PageState) => state.veranstaltungen);
