/**
 * orga app | veranstaltungen
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { AufgabeErinnerungNotification } from '../model/aufgabeErinnerungNotification';
import { AufgabeEskalationNotification } from '../model/aufgabeEskalationNotification';
import { AufgabeIdentifier } from '../model/aufgabeIdentifier';
import { AufgabeNichtOkNotification } from '../model/aufgabeNichtOkNotification';
import { ChecklisteIdentifier } from '../model/checklisteIdentifier';
import { ConstantTypeInfo } from '../model/constantTypeInfo';
import { DokumentIdentifier } from '../model/dokumentIdentifier';
import { EntityTypeInfo } from '../model/entityTypeInfo';
import { FehlerIdentifier } from '../model/fehlerIdentifier';
import { FunktionsbereichIdentifier } from '../model/funktionsbereichIdentifier';
import { FunktionsbereichOkNotification } from '../model/funktionsbereichOkNotification';
import { MeinDienstplanNotification } from '../model/meinDienstplanNotification';
import { MeldeartIdentifier } from '../model/meldeartIdentifier';
import { NotificationMessageTypeInfo } from '../model/notificationMessageTypeInfo';
import { RolleIdentifier } from '../model/rolleIdentifier';
import { VeranstaltungIdentifier } from '../model/veranstaltungIdentifier';
import { VeranstaltungenMandantOptions } from '../model/veranstaltungenMandantOptions';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class VeranstaltungenTemplatesService {

    protected basePath = '/';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * GetAufgabeErinnerungNotification
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getAufgabeErinnerungNotification(observe?: 'body', reportProgress?: boolean): Observable<AufgabeErinnerungNotification>;
    public getAufgabeErinnerungNotification(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<AufgabeErinnerungNotification>>;
    public getAufgabeErinnerungNotification(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<AufgabeErinnerungNotification>>;
    public getAufgabeErinnerungNotification(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<AufgabeErinnerungNotification>('get',`${this.basePath}/api/veranstaltungen/templates/notifications/aufgabeerinnerung`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * GetAufgabeEskalationNotification
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getAufgabeEskalationNotification(observe?: 'body', reportProgress?: boolean): Observable<AufgabeEskalationNotification>;
    public getAufgabeEskalationNotification(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<AufgabeEskalationNotification>>;
    public getAufgabeEskalationNotification(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<AufgabeEskalationNotification>>;
    public getAufgabeEskalationNotification(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<AufgabeEskalationNotification>('get',`${this.basePath}/api/veranstaltungen/templates/notifications/aufgabeeskalation`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * GetAufgabeIdentifier
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getAufgabeIdentifier(observe?: 'body', reportProgress?: boolean): Observable<AufgabeIdentifier>;
    public getAufgabeIdentifier(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<AufgabeIdentifier>>;
    public getAufgabeIdentifier(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<AufgabeIdentifier>>;
    public getAufgabeIdentifier(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<AufgabeIdentifier>('get',`${this.basePath}/api/veranstaltungen/templates/identifier/aufgabe`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * GetAufgabeNichtOkNotification
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getAufgabeNichtOkNotification(observe?: 'body', reportProgress?: boolean): Observable<AufgabeNichtOkNotification>;
    public getAufgabeNichtOkNotification(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<AufgabeNichtOkNotification>>;
    public getAufgabeNichtOkNotification(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<AufgabeNichtOkNotification>>;
    public getAufgabeNichtOkNotification(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<AufgabeNichtOkNotification>('get',`${this.basePath}/api/veranstaltungen/templates/notifications/aufgabenichtok`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * GetChecklisteIdentifier
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getChecklisteIdentifier(observe?: 'body', reportProgress?: boolean): Observable<ChecklisteIdentifier>;
    public getChecklisteIdentifier(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ChecklisteIdentifier>>;
    public getChecklisteIdentifier(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ChecklisteIdentifier>>;
    public getChecklisteIdentifier(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<ChecklisteIdentifier>('get',`${this.basePath}/api/veranstaltungen/templates/identifier/checkliste`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * GetDokumentIdentifier
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getDokumentIdentifier(observe?: 'body', reportProgress?: boolean): Observable<DokumentIdentifier>;
    public getDokumentIdentifier(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<DokumentIdentifier>>;
    public getDokumentIdentifier(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<DokumentIdentifier>>;
    public getDokumentIdentifier(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<DokumentIdentifier>('get',`${this.basePath}/api/veranstaltungen/templates/identifier/dokument`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * GetFehlerIdentifier
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getFehlerIdentifier(observe?: 'body', reportProgress?: boolean): Observable<FehlerIdentifier>;
    public getFehlerIdentifier(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<FehlerIdentifier>>;
    public getFehlerIdentifier(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<FehlerIdentifier>>;
    public getFehlerIdentifier(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<FehlerIdentifier>('get',`${this.basePath}/api/veranstaltungen/templates/identifier/fehler`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * GetFunktionsbereichIdentifier
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getFunktionsbereichIdentifier(observe?: 'body', reportProgress?: boolean): Observable<FunktionsbereichIdentifier>;
    public getFunktionsbereichIdentifier(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<FunktionsbereichIdentifier>>;
    public getFunktionsbereichIdentifier(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<FunktionsbereichIdentifier>>;
    public getFunktionsbereichIdentifier(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<FunktionsbereichIdentifier>('get',`${this.basePath}/api/veranstaltungen/templates/identifier/funktionsbereich`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * GetFunktionsbereichOkNotification
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getFunktionsbereichOkNotification(observe?: 'body', reportProgress?: boolean): Observable<FunktionsbereichOkNotification>;
    public getFunktionsbereichOkNotification(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<FunktionsbereichOkNotification>>;
    public getFunktionsbereichOkNotification(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<FunktionsbereichOkNotification>>;
    public getFunktionsbereichOkNotification(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<FunktionsbereichOkNotification>('get',`${this.basePath}/api/veranstaltungen/templates/notifications/funktionsbereichok`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * GetMeinDienstplanNotification
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getMeinDienstplanNotification(observe?: 'body', reportProgress?: boolean): Observable<MeinDienstplanNotification>;
    public getMeinDienstplanNotification(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<MeinDienstplanNotification>>;
    public getMeinDienstplanNotification(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<MeinDienstplanNotification>>;
    public getMeinDienstplanNotification(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<MeinDienstplanNotification>('get',`${this.basePath}/api/veranstaltungen/templates/notifications/meindienstplan`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * GetMeldeartIdentifier
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getMeldeartIdentifier(observe?: 'body', reportProgress?: boolean): Observable<MeldeartIdentifier>;
    public getMeldeartIdentifier(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<MeldeartIdentifier>>;
    public getMeldeartIdentifier(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<MeldeartIdentifier>>;
    public getMeldeartIdentifier(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<MeldeartIdentifier>('get',`${this.basePath}/api/veranstaltungen/templates/identifier/meldeart`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * GetNotificationTypes
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getNotificationTypes(observe?: 'body', reportProgress?: boolean): Observable<Array<NotificationMessageTypeInfo>>;
    public getNotificationTypes(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<NotificationMessageTypeInfo>>>;
    public getNotificationTypes(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<NotificationMessageTypeInfo>>>;
    public getNotificationTypes(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<NotificationMessageTypeInfo>>('get',`${this.basePath}/api/veranstaltungen/templates/notificationtypes`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * GetRolleIdentifier
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getRolleIdentifier(observe?: 'body', reportProgress?: boolean): Observable<RolleIdentifier>;
    public getRolleIdentifier(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<RolleIdentifier>>;
    public getRolleIdentifier(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<RolleIdentifier>>;
    public getRolleIdentifier(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<RolleIdentifier>('get',`${this.basePath}/api/veranstaltungen/templates/identifier/rolle`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * GetVeranstaltungIdentifier
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getVeranstaltungIdentifier(observe?: 'body', reportProgress?: boolean): Observable<VeranstaltungIdentifier>;
    public getVeranstaltungIdentifier(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VeranstaltungIdentifier>>;
    public getVeranstaltungIdentifier(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VeranstaltungIdentifier>>;
    public getVeranstaltungIdentifier(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<VeranstaltungIdentifier>('get',`${this.basePath}/api/veranstaltungen/templates/identifier/veranstaltung`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * GetVeranstaltungPruefberichtEmptyXmlTemplate
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getVeranstaltungPruefberichtEmptyXmlTemplate(observe?: 'body', reportProgress?: boolean): Observable<any>;
    public getVeranstaltungPruefberichtEmptyXmlTemplate(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public getVeranstaltungPruefberichtEmptyXmlTemplate(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public getVeranstaltungPruefberichtEmptyXmlTemplate(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('get',`${this.basePath}/api/veranstaltungen/templates/pruefbericht/emptydata`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * GetVeranstaltungPruefberichtXmlTemplate
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getVeranstaltungPruefberichtXmlTemplate(observe?: 'body', reportProgress?: boolean): Observable<any>;
    public getVeranstaltungPruefberichtXmlTemplate(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public getVeranstaltungPruefberichtXmlTemplate(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public getVeranstaltungPruefberichtXmlTemplate(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('get',`${this.basePath}/api/veranstaltungen/templates/pruefbericht/data`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * GetVeranstaltungPruefberichtXsdTemplate
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getVeranstaltungPruefberichtXsdTemplate(observe?: 'body', reportProgress?: boolean): Observable<any>;
    public getVeranstaltungPruefberichtXsdTemplate(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public getVeranstaltungPruefberichtXsdTemplate(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public getVeranstaltungPruefberichtXsdTemplate(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('get',`${this.basePath}/api/veranstaltungen/templates/pruefbericht/schema`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * GetVeranstaltungenConfigTypes
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getVeranstaltungenConfigTypes(observe?: 'body', reportProgress?: boolean): Observable<Array<ConstantTypeInfo>>;
    public getVeranstaltungenConfigTypes(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<ConstantTypeInfo>>>;
    public getVeranstaltungenConfigTypes(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<ConstantTypeInfo>>>;
    public getVeranstaltungenConfigTypes(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<ConstantTypeInfo>>('get',`${this.basePath}/api/veranstaltungen/templates/configtypes`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * GetVeranstaltungenConstants
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getVeranstaltungenConstants(observe?: 'body', reportProgress?: boolean): Observable<Array<ConstantTypeInfo>>;
    public getVeranstaltungenConstants(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<ConstantTypeInfo>>>;
    public getVeranstaltungenConstants(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<ConstantTypeInfo>>>;
    public getVeranstaltungenConstants(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<ConstantTypeInfo>>('get',`${this.basePath}/api/veranstaltungen/templates/constants`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * GetVeranstaltungenEntityTypes
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getVeranstaltungenEntityTypes(observe?: 'body', reportProgress?: boolean): Observable<Array<EntityTypeInfo>>;
    public getVeranstaltungenEntityTypes(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<EntityTypeInfo>>>;
    public getVeranstaltungenEntityTypes(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<EntityTypeInfo>>>;
    public getVeranstaltungenEntityTypes(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<EntityTypeInfo>>('get',`${this.basePath}/api/veranstaltungen/templates/entitytypes`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * GetVeranstaltungenMandantOptions
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getVeranstaltungenMandantOptions(observe?: 'body', reportProgress?: boolean): Observable<VeranstaltungenMandantOptions>;
    public getVeranstaltungenMandantOptions(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VeranstaltungenMandantOptions>>;
    public getVeranstaltungenMandantOptions(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VeranstaltungenMandantOptions>>;
    public getVeranstaltungenMandantOptions(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<VeranstaltungenMandantOptions>('get',`${this.basePath}/api/veranstaltungen/templates/config/veranstaltungenmandantoptions`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
