import * as api from '../../../clients/incidents'; 

import {Action} from '@ngrx/store';

export const GET_MEINE_INCIDENTS2 = '[IncidentsModul] GetMeineIncidents2 laden';
export const GET_MEINE_INCIDENTS2_AUSFUEHREN = '[IncidentsModul] GetMeineIncidents2 ausführen';
export const GET_MEINE_INCIDENTS2_ERFOLGREICH = '[IncidentsModul] GetMeineIncidents2 laden erfolgreich';
export const GET_MEINE_INCIDENTS2_FEHLER = '[IncidentsModul] GetMeineIncidents2 Ladefehler';
export const GET_MEINE_INCIDENTS2_NICHT_AUSGEFUEHRT = '[IncidentsModul] GetMeineIncidents2 nicht ausgeführt (evtl. Offline)';



export class GetMeineIncidents2Action implements Action {
    readonly type = GET_MEINE_INCIDENTS2;
        constructor( public veranstaltung_id:Array<string> , public optPayload: any = null) {}
    }
export class GetMeineIncidents2AusfuehrenAction implements Action {
    readonly type = GET_MEINE_INCIDENTS2_AUSFUEHREN;
        constructor( public veranstaltung_id:Array<string> , public optPayload: any = null) {}
    }
export class GetMeineIncidents2ErfolgreichAction implements Action {
    readonly type = GET_MEINE_INCIDENTS2_ERFOLGREICH;
        constructor(public payload: api.IncidentsListResponse,  public veranstaltung_id:Array<string> , public optPayload: any = null) {}
    }
export class GetMeineIncidents2FehlerAction implements Action {
    readonly type = GET_MEINE_INCIDENTS2_FEHLER;
        constructor(public payload: any,  public veranstaltung_id:Array<string> , public optPayload: any = null) {}
    }
export class GetMeineIncidents2NichtAusgefuehrtAction implements Action {
    readonly type = GET_MEINE_INCIDENTS2_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type IncidentsQueryMeineIncidents2Actions =
    GetMeineIncidents2Action
    | GetMeineIncidents2AusfuehrenAction
    | GetMeineIncidents2ErfolgreichAction
    | GetMeineIncidents2FehlerAction
    | GetMeineIncidents2NichtAusgefuehrtAction
    ;
