import {ModuleWithProviders, NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule} from '@angular/forms';
import {RouterModule, Routes} from '@angular/router';

import {IonicModule} from '@ionic/angular';

import {IncidentPage} from './incident.page';
import {SopiScrollModule} from '../../../sopi/components/sopi-scroll/sopi-scroll.module';
import {SopiComponentsModule} from '../../shared/sopi-components/sopi-components.module';
import {SopiDirectivesModule} from '../../../sopi/directives/sopi-directives.module';
import {TranslateModule} from '@ngx-translate/core';
import {EffectsModule} from '@ngrx/effects';
import {IncidentsIncidentAnlegen2Effects, IncidentsIncidentDetailEffects, IncidentsIncidentTitelUndBeschreibungSetzenEffects, IncidentsVeranstaltungenEffects} from '../../../client-ngrx/incidents';
import {IncidentPageUiEffects} from './incident.effects.ui';
import {StoreModule} from '@ngrx/store';
import {reducers} from './incident.reducer';

const routes: Routes = [
    {
        path: '',
        component: IncidentPage,
        data: { title: 'IncidentPage' }
    }
];

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        IonicModule,
        SopiScrollModule,
        RouterModule.forChild(routes),
        SopiComponentsModule.forRoot(),
        SopiDirectivesModule.forRoot(),
        StoreModule.forFeature('IncidentPage', reducers),
        TranslateModule,
        EffectsModule.forFeature([
            IncidentPageUiEffects,
            IncidentsIncidentAnlegen2Effects,
            IncidentsIncidentDetailEffects,
            IncidentsIncidentTitelUndBeschreibungSetzenEffects,

            IncidentsVeranstaltungenEffects

        ])
    ],
    declarations: [IncidentPage],
    entryComponents: [
        IncidentPage
    ]
})

export class NeuenIncidentPageModule {
    public static forRoot(): ModuleWithProviders {
        return {
            ngModule: NeuenIncidentPageModule,
        };
    }
}
