import * as api from '../../../clients/incidents'; 

import {Action} from '@ngrx/store';

export const GET_ZENTRALE_LISTE2 = '[IncidentsModul] GetZentraleListe2 laden';
export const GET_ZENTRALE_LISTE2_AUSFUEHREN = '[IncidentsModul] GetZentraleListe2 ausführen';
export const GET_ZENTRALE_LISTE2_ERFOLGREICH = '[IncidentsModul] GetZentraleListe2 laden erfolgreich';
export const GET_ZENTRALE_LISTE2_FEHLER = '[IncidentsModul] GetZentraleListe2 Ladefehler';
export const GET_ZENTRALE_LISTE2_NICHT_AUSGEFUEHRT = '[IncidentsModul] GetZentraleListe2 nicht ausgeführt (evtl. Offline)';



export class GetZentraleListe2Action implements Action {
    readonly type = GET_ZENTRALE_LISTE2;
        constructor( public veranstaltung_id:Array<string> , public optPayload: any = null) {}
    }
export class GetZentraleListe2AusfuehrenAction implements Action {
    readonly type = GET_ZENTRALE_LISTE2_AUSFUEHREN;
        constructor( public veranstaltung_id:Array<string> , public optPayload: any = null) {}
    }
export class GetZentraleListe2ErfolgreichAction implements Action {
    readonly type = GET_ZENTRALE_LISTE2_ERFOLGREICH;
        constructor(public payload: api.IncidentsListResponse,  public veranstaltung_id:Array<string> , public optPayload: any = null) {}
    }
export class GetZentraleListe2FehlerAction implements Action {
    readonly type = GET_ZENTRALE_LISTE2_FEHLER;
        constructor(public payload: any,  public veranstaltung_id:Array<string> , public optPayload: any = null) {}
    }
export class GetZentraleListe2NichtAusgefuehrtAction implements Action {
    readonly type = GET_ZENTRALE_LISTE2_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type IncidentsQueryZentraleListe2Actions =
    GetZentraleListe2Action
    | GetZentraleListe2AusfuehrenAction
    | GetZentraleListe2ErfolgreichAction
    | GetZentraleListe2FehlerAction
    | GetZentraleListe2NichtAusgefuehrtAction
    ;
