import * as api from '../../../clients/incidents'; 

import {Action} from '@ngrx/store';

export const GET_INCIDENT_BY_FUNKPROTOKOLL_FALL2 = '[IncidentsModul] GetIncidentByFunkprotokollFall2 laden';
export const GET_INCIDENT_BY_FUNKPROTOKOLL_FALL2_AUSFUEHREN = '[IncidentsModul] GetIncidentByFunkprotokollFall2 ausführen';
export const GET_INCIDENT_BY_FUNKPROTOKOLL_FALL2_ERFOLGREICH = '[IncidentsModul] GetIncidentByFunkprotokollFall2 laden erfolgreich';
export const GET_INCIDENT_BY_FUNKPROTOKOLL_FALL2_FEHLER = '[IncidentsModul] GetIncidentByFunkprotokollFall2 Ladefehler';
export const GET_INCIDENT_BY_FUNKPROTOKOLL_FALL2_NICHT_AUSGEFUEHRT = '[IncidentsModul] GetIncidentByFunkprotokollFall2 nicht ausgeführt (evtl. Offline)';



export class GetIncidentByFunkprotokollFall2Action implements Action {
    readonly type = GET_INCIDENT_BY_FUNKPROTOKOLL_FALL2;
        constructor( public fall_id:string , public optPayload: any = null) {}
    }
export class GetIncidentByFunkprotokollFall2AusfuehrenAction implements Action {
    readonly type = GET_INCIDENT_BY_FUNKPROTOKOLL_FALL2_AUSFUEHREN;
        constructor( public fall_id:string , public optPayload: any = null) {}
    }
export class GetIncidentByFunkprotokollFall2ErfolgreichAction implements Action {
    readonly type = GET_INCIDENT_BY_FUNKPROTOKOLL_FALL2_ERFOLGREICH;
        constructor(public payload: api.IncidentKopf,  public fall_id:string , public optPayload: any = null) {}
    }
export class GetIncidentByFunkprotokollFall2FehlerAction implements Action {
    readonly type = GET_INCIDENT_BY_FUNKPROTOKOLL_FALL2_FEHLER;
        constructor(public payload: any,  public fall_id:string , public optPayload: any = null) {}
    }
export class GetIncidentByFunkprotokollFall2NichtAusgefuehrtAction implements Action {
    readonly type = GET_INCIDENT_BY_FUNKPROTOKOLL_FALL2_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type IncidentsQueryIncidentByFunkprotokollFall2Actions =
    GetIncidentByFunkprotokollFall2Action
    | GetIncidentByFunkprotokollFall2AusfuehrenAction
    | GetIncidentByFunkprotokollFall2ErfolgreichAction
    | GetIncidentByFunkprotokollFall2FehlerAction
    | GetIncidentByFunkprotokollFall2NichtAusgefuehrtAction
    ;
