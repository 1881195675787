import {Action, ActionReducerMap} from '@ngrx/store';

import * as actionsUi from './veranstaltungselektion.actions.ui';
import {SetzeSelektierteVeranstaltungAction} from './veranstaltungselektion.actions.ui';
import {Veranstaltung} from '../../../clients/incidents';
import {GET_VERANSTALTUNGEN, GET_VERANSTALTUNGEN_ERFOLGREICH, GET_VERANSTALTUNGEN_FEHLER, GetVeranstaltungenErfolgreichAction} from '../../../client-ngrx/incidents';

export interface ModuleState {
    page: PageState;
    veranstaltungselektionPage: PageSaveState;
}

export const reducers: ActionReducerMap<ModuleState> = {
    page: pageReducer,
    veranstaltungselektionPage: pageSaveReducer
};

export interface PageSaveState {
    nurVeranstaltungenMitOffenenIncidents: boolean;
    selectedVeranstaltungsIds: Array<string>;
}

export const initialPageSaveState: PageSaveState = {
    nurVeranstaltungenMitOffenenIncidents: true,
    selectedVeranstaltungsIds: []
};

export interface PageState {
    veranstaltungen: Array<Veranstaltung>;
    veranstaltungenLaden: boolean;
    veranstaltungenFehler: boolean;
}

export const initialPageState: PageState = {
    veranstaltungen: [],
    veranstaltungenLaden: false,
    veranstaltungenFehler: false,
};

export function pageSaveReducer(state = initialPageSaveState, action: Action): PageSaveState {
    switch (action.type) {
        case actionsUi.SETZE_SELEKTIERTE_VERANSTALTUNG_IDS: {
            const a = <SetzeSelektierteVeranstaltungAction>action;
            return Object.assign({}, state, <PageSaveState>{selectedVeranstaltungsIds: a.payload});
        }

        case actionsUi.NUR_VERANSTALTUNGEN_MIT_OFFENEN_INCIDENTS: {
            const a = <actionsUi.NurVeranstaltungenMitOffenenIncidentsSetzenAction>action;

            return Object.assign({}, state, <PageSaveState>{
                nurVeranstaltungenMitOffenenIncidents: a.payload,
            });
        }

        case GET_VERANSTALTUNGEN_ERFOLGREICH: {
            const a = <GetVeranstaltungenErfolgreichAction> action;

            const ids: string[] = Object.assign([], state.selectedVeranstaltungsIds);

            state.selectedVeranstaltungsIds.forEach(id => {
               if (a.payload.findIndex(x => x.veranstaltung_id === id) === -1) {
                   const index = ids.findIndex(y => y === id);
                   ids.splice(index, 1);
               }
            });

            return Object.assign({}, state, <PageSaveState> {
                selectedVeranstaltungsIds: ids
            });
        }

        default: {
            return state;
        }
    }
}

export function pageReducer(state = initialPageState, action: Action): PageState {
    switch (action.type) {
        // Page State Reset
        case actionsUi.PAGE_STATE_RESET: {
            return Object.assign({}, state, <PageState>initialPageState);
        }

        // Veranstaltungen
        case GET_VERANSTALTUNGEN: {
            return Object.assign({}, state, <PageState>{
                veranstaltungenLaden: true,
                veranstaltungenFehler: false
            });
        }

        case GET_VERANSTALTUNGEN_ERFOLGREICH: {
            const a = <GetVeranstaltungenErfolgreichAction> action;

            return Object.assign({}, state, <PageState> {
                veranstaltungenLaden: false,
                veranstaltungenFehler: false,
                veranstaltungen: a.payload
            });
        }

        case GET_VERANSTALTUNGEN_FEHLER: {
            return Object.assign({}, state, <PageState>{
                veranstaltungenLaden: false,
                veranstaltungenFehler: true,
                veranstaltungen: []
            });
        }

        default: {
            return state;
        }
    }
}

