export interface TsAppVersion {
    version: string;
    name: string;
    description?: string;
    versionLong?: string;
    versionDate: string;
    gitCommitHash?: string;
    gitCommitDate?: string;
    gitTag?: string;
};
export const versions: TsAppVersion = {
    version: '1.49.1',
    name: 'orga-app-desktop-client',
    versionDate: '2025-03-21T13:50:27.555Z',
    description: 'ORGArena ORGA App',
    gitCommitHash: 'g52fc6f2b',
    gitCommitDate: '2025-03-21T10:44:38.000Z',
    versionLong: '1.49.1-g52fc6f2b',
    gitTag: 'v1.49.1',
};
export default versions;
